import dayjs from 'dayjs';
import React, { useState } from 'react';
import SingleDayMeetings from './SingleDayMeetings';
import CustomPopUp from '../../Common/Popups/CustomPopUp';
import { useDispatch } from 'react-redux';
import { getBulkMeetingPDFs, updateBulkOrderStatus } from '../../Actions/orderActions';
import UpdateMultipleOrderStatusPopup from '../../Common/Popups/UpdateMultipleOrderStatusPopup';
import Spinner from '../../Common/Spinner';
import { showToastNotification } from '../../Common/showToastNotification';
import '../../Common/common.css';

type DayMeetingProps = {
  singleDay: any;
  singleDayOrdersRestaurants: any;
  dateForDownloadingSummary: any;
  restaurant_ids_for_single_day_orders: any;
  isDownloadingExcelSummary: any;
  getExcelSummaryForAllMeetingsOnSingleDay: any;
  index: any;
  getOrderLast24HoursTrend: any;
  navigateToOrderDetails: any;
  vendorTimeZone: any
}
const DayMeeting = ({
  singleDay,
  singleDayOrdersRestaurants,
  dateForDownloadingSummary,
  restaurant_ids_for_single_day_orders,
  isDownloadingExcelSummary,
  getExcelSummaryForAllMeetingsOnSingleDay,
  index,
  getOrderLast24HoursTrend,
  navigateToOrderDetails,
  vendorTimeZone,
}: DayMeetingProps) => {
  const [checkedMeeting, setCheckedmeeting] = useState<boolean>(false)
  const [toggler, setToggler] = useState<boolean>(false)
  const [bulkAcceptArray, setBulkAcceptArray] = useState<any>([])
  const [order_confirm_status, setOrder_confirm_status] = useState<any>(false)
  const [bulkAcceptMeetings, setBulkAcceptMeetings] = useState<any>(Object.keys(bulkAcceptArray))
  const [isConfirmingOrder, setIsConfirmingOrder] = useState<boolean>(false)
  const [downloadInProgress, setDownloadingInProgress] = useState<boolean>(false)

  const dispatch: any = useDispatch();
  const resId: any = localStorage.getItem('remember_restaurant')
  const singleDayRestaurantCopyArray = Object.values(singleDayOrdersRestaurants)

  const filteredMeetingStatus = singleDayRestaurantCopyArray?.map((val: any) => {
    return val?.map((obj: any) => {
      return obj.status
    })
  })

  const filteredMeetingCutoff = singleDayRestaurantCopyArray?.map((val: any) => {
    return val?.map((obj: any) => {
      return obj.cutoff_at
    })
  })

  const cutoffReached = filteredMeetingCutoff?.flat(1)?.find(element => dayjs(element)?.isBefore(new Date()));

  const unConfirmedOrder = filteredMeetingStatus?.flat(1)?.find(element => element === 'Unconfirmed');
  const allCancelledOrders = filteredMeetingStatus?.flat(1)?.every(status => status === 'Cancelled')

  const StatusConfirmed = (status: any) => {
    setIsConfirmingOrder(true)
    handleSubmit()
  }
  const WaitingtoConfirm = () => {
    setIsConfirmingOrder(false)
    setOrder_confirm_status(true)
  }
  const DownloadingPDF = () => {
    setDownloadingInProgress(false)
  }
  const getUpdateBulkOrderStatus = () => {
    dispatch(updateBulkOrderStatus(resId, bulkAcceptMeetings, 'accept', WaitingtoConfirm))
  }
  const handleSubmit = () => {
    setIsConfirmingOrder(true)
    getUpdateBulkOrderStatus()
  };

  const bulkArray = (ids: any) => {
    let resultArray = bulkAcceptArray
    if (ids.meeting_ids.length < 1) {
      let bulkArrayOfMeetingIdCopy = resultArray
      delete bulkArrayOfMeetingIdCopy[ids.restaurant_address_id]
      setBulkAcceptArray(bulkArrayOfMeetingIdCopy)
    } else if (resultArray[ids.restaurant_address_id]) {
      resultArray = { ...resultArray, [ids.restaurant_address_id]: ids }
      // setBulkAcceptArray({...resultArray, [ids.restaurant_address_id]:ids})
    } else {
      let meetingSelected: any = {}
      meetingSelected[ids.restaurant_address_id] = ids
      resultArray = { ...resultArray, [ids.restaurant_address_id]: ids }
      // setBulkAcceptArray({...resultArray, [ids.restaurant_address_id]:ids })
    }
    setBulkAcceptArray(resultArray)
    setBulkAcceptMeetings(Object.values(resultArray))
  }

  const getBulkMeetingLabelPDFDownloader = (singleDayForDownload: any) => {
    const resId = localStorage.getItem('remember_restaurant')
    let DownloadPDF_Array: any = []
    Object.keys(singleDayOrdersRestaurants)?.forEach((restaurant) => {
      const restaurantOrdersOnSingleDay = singleDayOrdersRestaurants[restaurant];
      restaurantOrdersOnSingleDay?.forEach((value: any, idx: any) => {
        if (idx === 0) {
          DownloadPDF_Array.push(value?.restaurant_address_id)
        }
      })
    })
    if (!cutoffReached) {
      showToastNotification('Labels have not been generated because this delivery time has not reached cutoff. Please try again after cutoff.', 'Oops!');
    } else {
      setDownloadingInProgress(true)
      const fileName = 'Chowmill Labels';
      dispatch(getBulkMeetingPDFs(resId, singleDayForDownload, DownloadPDF_Array, 'labels_pdf.pdf', fileName, DownloadingPDF))
    }
  }

  const getBulkMeetingPDFDownloader = (singleDayForDownload: any) => {
    const resId = localStorage.getItem('remember_restaurant')
    let DownloadPDF_Array: any = []
    Object.keys(singleDayOrdersRestaurants)?.forEach((restaurant) => {
      const restaurantOrdersOnSingleDay = singleDayOrdersRestaurants[restaurant];
      restaurantOrdersOnSingleDay?.forEach((value: any, idx: any) => {
        if (idx === 0) {
          DownloadPDF_Array.push(value?.restaurant_address_id)
        }
      })
    })
    const fileName = 'Chowmill Order Summary';
    setDownloadingInProgress(true)
    dispatch(getBulkMeetingPDFs(resId, singleDayForDownload, DownloadPDF_Array, 'summary_pdf.pdf', fileName, DownloadingPDF))
  }

  const DownloadingExcel = () => {
    setDownloadingInProgress(true)
    getExcelSummaryForAllMeetingsOnSingleDay(
      [...new Set(restaurant_ids_for_single_day_orders as unknown as number[])],
      dateForDownloadingSummary,
      DownloadingPDF
    )
  }

  const BulkAccept = (value: any) => {
    if (value) {
      setCheckedmeeting(value)
    } else {
      setCheckedmeeting(value)
    }
  }
  const SingleDayOrdersHeading = ({ singleDay, restaurant_ids_for_single_day_orders, dateForDownloadingSummary, isDownloadingExcelSummary }: {
    singleDay: string[] | string, restaurant_ids_for_single_day_orders: number[], dateForDownloadingSummary: string, isDownloadingExcelSummary: (boolean | undefined);
  }) =>
    <div className='d-flex flex-wrap align-items-center mt-4' style={{ 'width': '100%' }}>
      <div className='mr-3 order-date-heading'>
        <h3 className='m-0'>{dayjs(`${singleDay}`).format('ddd, MMM. DD')}</h3>
      </div>
      {/* {true ? <div className='order-accept-container' onClick={()=>getUpdateBulkOrderStatus()}> */}
      <div className='col order-action-btns'>
        {!unConfirmedOrder ? <div className='order-accept-container'>
          {allCancelledOrders ? <button className='btn cancelled-order-btn all-orders-cancelled' disabled>
            <span className='mr-1'>
              <i className='fa-light fa-times-circle'></i>
            </span>
            All Orders Cancelled
          </button> :
            <button className='btn accept-order-btn all-orders-accepted' disabled>
              <span className='mr-1'>
                <i className='fa-light fa-circle-check'></i>
              </span>
              All Orders Accepted
            </button>
          }
        </div>
          :
          checkedMeeting ?
            <div
              className='order-accept-container'
              onClick={() => StatusConfirmed(true)}
            >
              <button className='btn accept-order-btn ' >
                {isConfirmingOrder ?
                  <Spinner size='spinner-border-sm mx-1' />
                  :
                  "Accept Order"
                }
              </button>
            </div>
            :
            <div className='order-accept-container'>
              <button className='btn accept-order-btn ' disabled>
                Accept Order
              </button>
            </div>

        }
        {!allCancelledOrders &&
          <div className='order-download-container'>
            <button
              onClick={() => setToggler(!toggler)}
              className={`btn  order-download-btn `}
              disabled={downloadInProgress}
            >
              Download
            </button>
            <button className='btn oder-download-arrow-btn' disabled={downloadInProgress} onClick={() => setToggler(!toggler)}>
              {downloadInProgress ? <Spinner size='spinner-border-sm mx-1' />
                :
                <i style={{ fontSize: 14 }} className='far fa-arrow-to-bottom mx-2'></i>
              }
            </button>

            <div>
              {toggler &&
                <CustomPopUp
                  backdropStyles={{ opacity: 0, cursor: 'default' }}
                  onPopUpClose={() => setToggler(false)}
                  popUpClassName='order-history-range-picker'
                  popUpBodyStyles={{ left: 0 }}
                >
                  <div
                    style={{ cursor: 'default', width: 172, maxHeight: 160, overflowY: 'auto' }}
                    className='bg-white order-restaurant-selector'
                  >
                    <div className='download-options-list' >
                      <p className='p-2' onClick={() => !downloadInProgress && getBulkMeetingPDFDownloader(singleDay)}>Orders Summary (PDF)</p>
                      <p className='p-2' onClick={() => { !downloadInProgress && setDownloadingInProgress(true); !downloadInProgress && DownloadingExcel() }}>All Orders (XLS)</p>
                      <p className='p-2' onClick={() => !downloadInProgress && getBulkMeetingLabelPDFDownloader(singleDay)}>Labels (PDF)</p>
                    </div>
                  </div>
                </CustomPopUp>
              }
            </div>
          </div>
        }
      </div>

    </div>

  return (
    <div key={singleDay} id={`date-${singleDay}`} className='bg-white pt-3 px-5 pb-5 my-5 rounded-5 day-card-date'>
      <span className="new-tag hidden">NEW</span>
      <div>
        <SingleDayOrdersHeading
          singleDay={singleDay}
          isDownloadingExcelSummary={isDownloadingExcelSummary}
          dateForDownloadingSummary={dateForDownloadingSummary}
          restaurant_ids_for_single_day_orders={restaurant_ids_for_single_day_orders}
        />
      </div>
      <div className='table-responsive mb-3'>
        {Object.keys(singleDayOrdersRestaurants).map((restaurantName: string) => {
          const restaurantOrdersOnSingleDay = singleDayOrdersRestaurants[restaurantName];
          return <div key={restaurantName}>
            {false ?
              <div style={{ marginBottom: 10 }} key={restaurantName}>
                <div className='d-flex align-items-center pb-2 pt-4'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      name='select-all'
                      id='select-all-toggler'
                      className='cursor-pointer'
                    />
                  </div>
                  <div>
                  </div>
                  <label htmlFor="select-all-orders" className='mb-0'>
                    <i className='fas fa-store' style={{ color: '#157FFC' }} />
                    <h5 className='pl-2 m-0 d-inline-block' style={{ color: '#157FFC' }}>{restaurantName}</h5>
                  </label>
                </div>
                <table className='table order-history-records table-hover border-0 table-data text-center'>
                </table>
              </div>
              :
              <div key={restaurantName}>
                <SingleDayMeetings
                  singleDay={singleDay}
                  index={restaurantName}
                  restaurant_ids_for_single_day_orders={restaurant_ids_for_single_day_orders}
                  getOrderLast24HoursTrend={getOrderLast24HoursTrend}
                  navigateToOrderDetails={navigateToOrderDetails}
                  vendorTimeZone={vendorTimeZone}
                  restaurantOrdersOnSingleDay={restaurantOrdersOnSingleDay}
                  restaurantName={restaurantName}
                  BulkAccept={BulkAccept}
                  bulkArray={bulkArray}
                  unconfirmedMeets={!unConfirmedOrder}
                />
              </div>
            }</div>
        }
        )}
      </div>
      {order_confirm_status &&
        <UpdateMultipleOrderStatusPopup
          StatusConfirmed={StatusConfirmed}
          bulkAcceptArray={bulkAcceptMeetings}
          resId={resId}
        />
      }
    </div>
  );
}

export default DayMeeting;